import { template as template_f4e72ea818444e84934756978ae36875 } from "@ember/template-compiler";
const FKLabel = template_f4e72ea818444e84934756978ae36875(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
